.menu-button {
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000; /* Ensure it’s above other content */
}

@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .custom-menu {
    display: none;
    
  }
  
}

.navlink{
  /* background-image:linear-gradient(25deg, #3c579c , #fff);
   */
   background-color: white;
}
.menu-item-content {
  display: flex;
  /* justify-content: ;
   */
   margin-left: 1px ;
  /* align-items: center; Vertically centers items */
}

.menu-item-content img {
  margin-right: 8px; /* Space between icon and text */
}
.demo-heading{ background-color: darkblue;}
.demo-link {
  color: white !important;
  transition: color 0.3s ease;
 
}

.demo-link:hover {
  color: black !important ;
}