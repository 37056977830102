@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

html ,body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  padding:  0;
  overflow-x: hidden;
  
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
 
}
.herotxt{
  font-family: "Archivo Black";
  font-weight: 1500;
  font-style: normal;
}

.footer {
  background-image: linear-gradient(#3c579c,#fff );
 color: black;
  /* background: url(./images/footer.svg) top center no-repeat; */
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}
.outlined-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.outlined-table th,
.outlined-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.outlined-table th {
  background-color: #f2f2f2;
}


